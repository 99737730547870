import React from 'react'

const LandingPage = () => {
const iframe = '<iframe frameborder="0" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px" height="100%" width="100%" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fr593GrZWS5FuBQVTGLHLWf%2FSearch-Landing-Pages%3Fpage-id%3D401%253A3002%26node-id%3D401%253A3003%26viewport%3D692%252C1073%252C0.25%26scaling%3Dmin-zoom%26starting-point-node-id%3D401%253A3003" allowfullscreen></iframe>'
  return (
    <>
     <div dangerouslySetInnerHTML={{__html: iframe}} />
    </>
  )
}

export default LandingPage
